import api from './Api';

interface Evento {
    'id': string,
    'dispositivo_id': string,
    'tag': string,
    'sistema_id': string,
    'ts': string,
    'raw_ts': number,
    'evento': string,
    'criticidade': number
}

export type { Evento };

export default {
    GetLatestByClientId: async (client_id: string, limit: number) => {
        return api.get(`/eventos/latest?client_id=${client_id}&limit=${limit}`);
    }
}