import api from './Api';
import AWS from 'aws-sdk';

AWS.config.update({
    accessKeyId: 'AKIASOED2MDTF3D7QKMF',
    secretAccessKey: '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',
    region: 'us-east-1'
});
const s3 = new AWS.S3();

interface Ativo {
    id: string;
    nome: string;
    tag: string;
    setor: string;
    image: string | undefined;
    pontos_coleta: string[];
    sistema_id: string;
}

interface AtivoCreate {
    nome: string;
    tag: string;
    setor: string;
    image: string | undefined;
    pontos_coleta: string[];
    sistema_id: string;
}

export type { Ativo };

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    create: async (ativo: AtivoCreate) => {
        return api.post(`/provibAtivos`, ativo).then(response => response.data);
    },
    list: async (sistemaId: string): Promise<Ativo[]> => {
        return api.get(`/provibAtivos?sistema_id=${sistemaId}`).then(response => response.data);
    },
    update: async (ativo: Ativo) => {
        return api.put(`/provibAtivos`, ativo).then(response => response.data);
    },
    delete: async (ativoId: string) => {
        return api.delete(`/provibAtivos?ativo_id=${ativoId}`).then(response => response.data);
    },
    uploadImage: async (ativo: Ativo, file: File) => {
        const params = {
            Bucket: 'painel-proativa',
            Key: `STATIC/${ativo.sistema_id}/${ativo.id}`,
            Body: file,
            ACL: 'public-read' // if you want the file to be publicly accessible
        };

        return new Promise((resolve, reject) => {
            s3.upload(params, (err: any, data: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(data);
                }
            });
        });
    },

}