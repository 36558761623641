import axios from "axios";
import Toast from "./Toast";

let ambiente = 'prod';

let ambientes: { [key: string]: string } = {
    'dev': "http://127.0.0.1:5000/api/v1/",
    'prod': "https://2e5wvkau36re7toncu3r66g4sy0gyuzq.lambda-url.us-east-1.on.aws/api/v1/",
    "hml": "https://c4gvutuvwkxdosch74vsciuslq0qudif.lambda-url.us-east-1.on.aws/api/v1/"
};
let api = axios.create({ baseURL: ambientes[ambiente] })

api.interceptors.request.use(function (request: any) {
    if (window.sessionStorage.provib_telemetria) {
        request['headers']["Authorization"] = "Bearer " + JSON.parse(window.sessionStorage.provib_telemetria).AccessToken;
    }
    return request;
});
api.interceptors.response.use(function (response: any) {
    return response;
}, function (error: any) {
    if (error.response === undefined) {
        Toast.error("Erro de conexão com o servidor");
    } else if (error.response.status === 401) {
        if (!window.location.href.includes("/login"))
            window.location.href = "login";
    }
    return Promise.reject(error);
});

export default api