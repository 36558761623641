import React, { useEffect } from 'react';

import { useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { IconButton, MenuItem, Select } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import TabelaCrud from '../../components/TabelaCrud';
import ClienteRepo, { Cliente } from '../../Services/ClienteRepo';
import Toast from '../../Services/Toast';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ModalConfirmar } from '../../components/Modais';
import { useLoggedUser } from '../../Contexts/LoggedUserContext';
import { ModalCriarCliente } from '../../components/Modais';

export default function ProvibAtivosCrud() {
  const queryClient = useQueryClient();

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [openCriarCliente, setOpenCriarCliente] = useState(false);

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [clienteIdDelete, setClienteIdDelete] = useState<string>("");

  const { isPending: isPendingUser } = useLoggedUser();

  const { data, isLoading, isFetching, error } = useQuery({
    queryKey: ['Clientes'],
    queryFn: () => ClienteRepo.getAll().catch(() => { Toast.error("Erro ao carregar Clientes") }),
    enabled: !isPendingUser,
  });



  let columns: GridColDef[] = [
    { field: "id", headerName: "Identificador", type: 'string', flex: 800, editable: false },
    { field: "nome", headerName: "Nome", type: 'string', flex: 800, editable: true },
    {
      field: "contrato", headerName: "Contrato", type: 'string', flex: 800, editable: false,
      renderCell: (params) => <SelectContratoState params={params} onCellEditCommit={handleOnCellEditCommit} />
    },
    {
      field: "actions",
      headerName: "Ações",
      type: 'string',
      flex: 200,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() => {
              setClienteIdDelete(params.id as string);
              setOpenConfirmDelete(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </>
      )
    }
  ];

  const handleCriarCliente = (name: string, contrato: string) => {
    ClienteRepo.create({ nome: name, contrato: contrato as "ATIVO" | "INATIVO" | "CANCELADO" }).then(() => {
      setRefresh(!refresh);
      Toast.success("Cliente criado com sucesso.");
      queryClient.invalidateQueries({ queryKey: ['Clientes'] });
    }).catch(() => {
      Toast.error("Erro ao criar cliente.");
    }).finally(() => {
      setOpenCriarCliente(false);
    });
  };

  const handleConfirmClienteDelete = () => {
    setLoading(true);
    ClienteRepo.delete(clienteIdDelete).then(() => {
      setRefresh(!refresh);
      Toast.success("Cliente deletado com sucesso.");
      queryClient.invalidateQueries({ queryKey: ['Clientes'] });
    }).catch((err) => {

      Toast.error("Erro ao deletar cliente.");
    }).finally(() => {
      setOpenConfirmDelete(false);
      setLoading(false);
    });
  };

  const updateClienteMutation = useMutation({
    mutationFn: ({ id, field, value }: { id: string, field: string, value: any }) => {
      let cliente: any = data?.find((cliente: Cliente) => cliente.id === id);

      if (!cliente)
        return Promise.reject("Cliente não encontrado");

      cliente[field] = value;

      return ClienteRepo.update(cliente as Cliente)
        .finally(() =>
          queryClient.invalidateQueries({ queryKey: ['Clientes'] })
        );
    },
    onSuccess: () => {
      Toast.success("Cliente atualizado com sucesso!");
    },
    onError: (err: any) => {
      if (err.response?.status === 403) {
        Toast.error("Você não tem permissão para editar Cliente!");
      } else {
        Toast.error("Erro ao atualizar Cliente!");
      }
    }
  });

  function handleOnCellEditCommit(params: GridRenderCellParams) {
    updateClienteMutation.mutate({ id: params.id as string, field: params.field as string, value: params.value });
  }


  return (
    <>
      <TabelaCrud
        onRefreshClick={() => { queryClient.invalidateQueries({ queryKey: ['Clientes'] }) }}
        onAddClick={() => setOpenCriarCliente(true)}
        loading={isLoading || isFetching || loading}
        error={error}
        rows={data ?? []}
        columns={columns}
        onCellEditCommit={handleOnCellEditCommit}
      />


      <ModalConfirmar
        open={openConfirmDelete}
        setOpen={setOpenConfirmDelete}
        onConfirm={handleConfirmClienteDelete}
        title={'Tem certeza que deseja deletar o cliente?'}
        message={'Confirmar exclusão permanente de cliente.'}
      />

      <ModalCriarCliente
        open={openCriarCliente}
        setOpen={setOpenCriarCliente}
        criarCliente={handleCriarCliente}
      />
    </>
  );
}


function SelectContratoState(props: { params: GridRenderCellParams<any, any>, onCellEditCommit: Function }) {
  const [enabled, setEnabled] = React.useState(false);

  return (
    <Select
      sx={{ width: '-webkit-fill-available' }}
      labelId="select-label"
      id="contrato_select"
      label="Contrato"
      disabled={!enabled}
      placeholder="Estado do contrato"
      value={props.params.row?.contrato}
      onDoubleClick={() => setEnabled(true)}
      onChange={(event) => {
        const newValue = event.target.value;
        setEnabled(false);
        props.onCellEditCommit({ id: props.params.id, field: 'contrato', value: newValue });
      }}
    >
      <MenuItem value={"ATIVO"}>ATIVO</MenuItem>
      <MenuItem value={"INATIVO"}>INATIVO</MenuItem>
      <MenuItem value={"CANCELADO"}>CANCELADO</MenuItem>
    </Select>
  );
}
