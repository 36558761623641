import React, { useEffect } from 'react';

import { useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Button, Dialog, DialogTitle, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import TabelaCrud from '../../components/TabelaCrud';
import ProvibAtivoRepo, { Ativo } from '../../Services/ProvibAtivos';
import Toast from '../../Services/Toast';
import { useSistemaSelecionado } from '../../Contexts/BaseContext';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { MuiFileInput } from 'mui-file-input';
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { ModalConfirmar } from '../../components/Modais';
import {ModalCriarProvibAtivo, ModalEditarPontosDeColeta} from '../../components/Modais';

export default function ProvibAtivosCrud() {
  const queryClient = useQueryClient();

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [provibAtivoIdDelete, setProvibAtivoIdDelete] = useState<string>("");

  const [openCriarAtivo, setOpenCriarAtivo] = useState(false);
  const [openEditarPontosDeColeta, setOpenEditarPontosDeColeta] = useState(false);

  const { sistemaSelecionado } = useSistemaSelecionado();

  const [selectedAtivo, setSelectedAtivo] = useState<Ativo | null>();

  const [openImageSelector, setOpenImageSelector] = useState(false);
  const [imageFileValue, setImageFileValue] = useState<File | null>(null);

  const { data, isLoading, isFetching, error } = useQuery({
    queryKey: ['ProvibAtivos', sistemaSelecionado],
    queryFn: () => ProvibAtivoRepo.list(sistemaSelecionado ?? "").catch(() => { Toast.error("Erro ao carregar Ativos") }),
    enabled: !!sistemaSelecionado,
  });

  const updateProvibAtivoMutation = useMutation({
    mutationFn: ({ id, field, value }: { id: string, field: string, value: any }) => {
      let ativo: any = data?.find((ativo: Ativo) => ativo.id === id);
      if (!ativo)
        return Promise.reject("Ativo não encontrado");

      ativo[field] = value;

      return ProvibAtivoRepo.update(ativo as Ativo)
        .finally(() =>
          queryClient.invalidateQueries({ queryKey: ['ProvibAtivos', sistemaSelecionado] })
        );
    },
    onSuccess: () => {
      Toast.success("Ativo atualizado com sucesso!");
      queryClient.invalidateQueries({ queryKey: ['ProvibAtivos', sistemaSelecionado] });
    },
    onError: (err: any) => {
      if (err.response?.status === 403) {
        Toast.error("Você não tem permissão para editar ativos!");
      } else {
        Toast.error("Erro ao atualizar ativo!");
      }
    }
  });

  const handleConfirmProvibAtivoDelete = () => {
    setLoading(true);
    ProvibAtivoRepo.delete(provibAtivoIdDelete).then(() => {
      setRefresh(!refresh);
      Toast.success("Ativo deletado com sucesso.");
      queryClient.invalidateQueries({ queryKey: ['ProvibAtivos', sistemaSelecionado] });
    }).catch((err) => {

      Toast.error("Erro ao deletar ativo.");
    }).finally(() => {
      setOpenConfirmDelete(false);
      setLoading(false);
    });
  };

  const handleCriarProvibAtivos = (nome: string, tag: string, setor: string, pontos_coleta: string[]) => {
    setLoading(true);
    if (!sistemaSelecionado) {
      Toast.error("Selecione um sistema.");
      return;
    }

    let ativo = {
      nome,
      tag,
      setor,
      pontos_coleta: pontos_coleta,
      sistema_id: sistemaSelecionado
    }

    ProvibAtivoRepo.create(ativo as Ativo).then(() => {
      setRefresh(!refresh);
      Toast.success("Ativo criado com sucesso.");
      queryClient.invalidateQueries({ queryKey: ['ProvibAtivos', sistemaSelecionado] });
    }).catch((err) => {
      if (err.response && err.response?.status === 403) {
        Toast.error("Sem permissão necessaria.");
      } else {
        Toast.error("Erro ao criar ativo.");
      }
    }).finally(() => {
      setOpenCriarAtivo(false);
      setLoading(false);
    });
  };

  const handleOnCellEditCommit = (params: any) => {
    updateProvibAtivoMutation.mutate({ id: params.id, field: params.field, value: params.value });
  };

  const uploadImageMutation = useMutation({
    mutationFn: ({ row, file }: { row: any, file: File }) => ProvibAtivoRepo.uploadImage(row, file),
    onSuccess: (data: any) => {
      updateProvibAtivoMutation.mutate({ id: selectedAtivo?.id!, field: "image", value: data.Location ?? "" })
    },
    onError: () => {
      Toast.error("Erro ao atualizar imagem");
    }
  });

  function uploadImage(file: File | null) {
    if (!file) return;
    uploadImageMutation.mutate({ row: selectedAtivo, file });
    setImageFileValue(null);
  }

  let columns: GridColDef[] = [
    { field: "id", headerName: "Identificador", type: 'string', flex: 800, editable: false },
    { field: "nome", headerName: "Nome", type: 'string', flex: 800, editable: true },
    { field: "tag", headerName: "Tag", type: 'string', flex: 800, editable: true },
    { field: "setor", headerName: "Setor", type: 'string', flex: 800, editable: true },
    { field: "recorrencia", headerName: "Recorrência", type: 'string', flex: 800, editable: true },
    {
      field: "pontos_coleta", headerName: "Pontos de Coleta", type: 'string', flex: 800, editable: true,
      renderCell: (params: GridRenderCellParams<any, Ativo>) => (
        <Button
          sx={{ width: '-webkit-fill-available', height: '100%' }}
          variant="outlined"
          color="primary"
          onClick={() => { setSelectedAtivo(params.row); setOpenEditarPontosDeColeta(true) }}
        >
          {(params.row.pontos_coleta?.length ?? 0) + "  Pontos de coleta"}
        </Button>
      )
    },
    {
      field: 'image', headerName: 'Foto', flex: 800,
      renderCell: (params: GridRenderCellParams<any, Ativo>) => (
        <IconButton
          sx={{ margin: "auto" }}
          onClick={() => { setSelectedAtivo(params.row); setOpenImageSelector(true) }}
        >
          <UploadFileIcon />
        </IconButton>
      ),
    },
    {
      field: 'delete', headerName: 'Deletar', flex: 800,
      renderCell: (params: GridRenderCellParams<any, any>) => (
        <IconButton
          sx={{ margin: "auto" }}
          onClick={() => { setProvibAtivoIdDelete(params.row.id); setOpenConfirmDelete(true) }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    }
  ];

  return (
    <>
      <TabelaCrud
        onRefreshClick={() => { queryClient.invalidateQueries({ queryKey: ['ProvibAtivos', sistemaSelecionado] }) }}
        onAddClick={() => { setOpenCriarAtivo(true) }}
        loading={isLoading || isFetching || loading}
        error={error}
        rows={data ?? []}
        columns={columns}
        onCellEditCommit={handleOnCellEditCommit}
      />


      <ModalConfirmar
        open={openConfirmDelete}
        setOpen={setOpenConfirmDelete}
        onConfirm={handleConfirmProvibAtivoDelete}
        title={'Tem certeza que deseja deletar o ativo?'}
        message={'Confirmar exclusão permanente de ativo.'}
      />

      <ModalCriarProvibAtivo
        open={openCriarAtivo}
        setOpen={setOpenCriarAtivo}
        criarAtivo={handleCriarProvibAtivos}
      />

      <ModalEditarPontosDeColeta
        open={openEditarPontosDeColeta}
        setOpen={setOpenEditarPontosDeColeta}
        pontosDeColeta={selectedAtivo?.pontos_coleta ?? []}
        onConfirm={(pontosDeColeta: string[]) => {
          updateProvibAtivoMutation.mutate({ id: selectedAtivo?.id!, field: "pontos_coleta", value: pontosDeColeta });
        }}
      />

      <Dialog
        open={openImageSelector}
        onClose={() => setOpenImageSelector(false)}
        style={{ maxWidth: "100%", maxHeight: "100%" }}
      >
        <DialogTitle id="alert-dialog-title">
          Selecione uma imagem para o Ativo {selectedAtivo?.nome}:
        </DialogTitle>
        <img
          style={{ maxHeight: "500px", maxWidth: "500px", margin: "auto" }}
          src={selectedAtivo?.image}
          alt="image"
        />
        <MuiFileInput
          size="small"
          variant="outlined"
          title={"Escolha uma imagem"}
          placeholder="Escolha uma imagem"
          margin="dense"
          sx={{ margin: "10px 10px 10px" }}
          InputProps={{
            inputProps: {
              accept: 'image/png, image/jpeg', // This line restricts the file input to .png, .jpg, and .jpeg files
            },
            startAdornment: <AttachFileIcon />
          }}
          value={imageFileValue}
          onChange={(file: File | null) => {
            setImageFileValue(file);
            uploadImage(file as File);
          }}
        />
      </Dialog>
    </>
  );
}