import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./Pages/Login/index";
import ConfirmarEmail from "./Pages/ConfirmarEmail/index";
import ListagemDispositivos from "./Pages/Dispositivos";
import ForgotPassword from "./Pages/ForgotPassword";
import ListagemGruposDispositivos from "./Pages/GuposDispositivos";
import Template from "./components/Template";
import Usuarios from "./Pages/Usuarios";
import HomeDashboard from "./Pages/DashBoards/HomeDashboard";
import SaudePlanta from "./Pages/DashBoards/SaudePlanta";
import AnaliseAtivos from "./Pages/DashBoards/Ativos";
import Cascata from "./Pages/DashBoards/Cascata";
import Analise from "./Pages/DashBoards/Analise";
import Alarmes from "./Pages/Alarmes";
import { LoggedUserProvider } from "./Contexts/LoggedUserContext";
import GestaoAvista from "./Pages/DashBoards/GestaoAvista";
import GestaoAvistaAtivos from "./Pages/DashBoards/GestaoAvistaAtivos";
import ProvibAtivosCrud from "./Pages/ProvibAtivos";
import Clientes from "./Pages/Clientes";

function Rotas() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/primeiro-acesso" element={<ConfirmarEmail />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route element={<LoggedUserProvider />}>
        <Route path="/dispositivos" element={
          <Template
            currentPageMenu={'Dispositivos'}
            content={<ListagemDispositivos />}
          />
        }
        />
        <Route path="/grupo-dispositivos" element={
          <Template
            currentPageMenu={'GruposDispositivos'}
            content={<ListagemGruposDispositivos />}
          />
        }
        />
        <Route path="/alarmes" element={
          <Template
            currentPageMenu={'Alarmes'}
            content={<Alarmes />}
          />
        }
        />
        <Route path="/usuarios" element={
          <Template
            currentPageMenu={'Usuarios'}
            content={<Usuarios />}
          />
        }
        />

        <Route path="/home" element={
          <Template
            currentPageMenu={'HomeDash'}
            content={
              <HomeDashboard />}
          />
        }
        />
        <Route path="/saude-planta" element={
          <Template
            currentPageMenu={'SaudePlanta'}
            content={<SaudePlanta />}
          />
        }
        />

        <Route path="/analise-ativos" element={
          <Template
            currentPageMenu={'Ativos'}
            content={
              <AnaliseAtivos />}
          />
        }
        />

        <Route path="/cascata" element={
          <Template
            currentPageMenu={'Cascata'}
            content={<Cascata />}
          />
        }
        />

        <Route path="/analise" element={
          <Template
            currentPageMenu={'Analise'}
            content={<Analise />}
          />
        }
        />
        <Route path='/gestao-a-vista' element={
          <Template
            currentPageMenu={'GestaoAVista'}
            content={<GestaoAvista />}
          />
        }
        />
        <Route path='/gestao-a-vista-ativos' element={
          <Template
            currentPageMenu={"GestaoAvistaAtivos"}
            content={<GestaoAvistaAtivos />}
          />
        } />
        <Route path="/ativos_provib" element={
          <Template
            currentPageMenu={'ProvibAtivos'}
            content={<ProvibAtivosCrud />}
          />
        }
        />
        <Route path="/clientes" element={
          <Template
            currentPageMenu={'Clientes'}
            content={<Clientes />}
          />
        }
        />
      </Route>
      <Route path="*" element={<Login />} />
    </Routes>
  );
}
export default Rotas;
