import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Chip,
  Autocomplete
} from "@mui/material";

export default function ModalCriarProvibAtivo(props: { open: boolean, setOpen: Function, criarAtivo: Function }) {
  const [nome, setNome] = React.useState("");
  const [tag, setTag] = React.useState("");
  const [setor, setSetor] = React.useState("");
  const [pontosDeColeta, setPontosDeColeta] = React.useState<string[]>([]);

  return (
    <Dialog
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Criar Ativo</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Para criar um novo ativo, preencha os campos abaixo.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Nomaae"
          type="text"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          required
          error={nome.length < 1}
          fullWidth
        />
        <TextField
          margin="dense"
          id="tag"
          label="Tag"
          type="text"
          value={tag}
          onChange={(e) => setTag(e.target.value)}
          required
          error={tag.length < 1}
          fullWidth
        />
        <TextField
          margin="dense"
          id="setor"
          label="Setor"
          type="text"
          value={setor}
          onChange={(e) => setSetor(e.target.value)}
          required
          error={setor.length < 1}
          fullWidth
        />
        <Autocomplete
          multiple
          freeSolo
          options={[]}  // Sem opções pré-definidas, o usuário insere seus próprios valores
          value={pontosDeColeta}
          onChange={(event, newValue) => setPontosDeColeta(newValue)}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              label="Pontos de Coleta"
              placeholder="Digite e pressione Enter"
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setOpen(false)} color="primary">
          Cancelar
        </Button>
        <Button onClick={() => props.criarAtivo(nome, tag, setor, pontosDeColeta)} color="primary" autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
