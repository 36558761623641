import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import TabelaCrud from "../../components/TabelaCrud";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import UsuariosRepo, { Usuario } from "../../Services/UsuariosRepo";
import { Button, MenuItem, Select, Stack, Typography } from "@mui/material";
import { styled, Tooltip, tooltipClasses } from '@mui/material';

import { TooltipProps } from '@mui/material';
import { ModalAcessoClientes, ModalCriarUsuario, ModalPermissoesUsuario } from "../../components/Modais";
import Toast from "../../Services/Toast";
import { useLoggedUser } from "../../Contexts/LoggedUserContext";
import { useSistemaSelecionado } from "../../Contexts/BaseContext";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(15),
    border: '1px solid #dadde9',
  },
}));

export default function Usuarios() {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [rows, setRows] = React.useState<any[]>([]);
  const [refresh, setRefresh] = React.useState<boolean>(false);

  const [openEditAcesso, setOpenEditAcesso] = React.useState(false);
  const [openEditPermicoes, setOpenEditPermicoes] = React.useState(false);

  const [sistemasAcessoSelectionados, setSistemasAcessoSelectionados] = React.useState<any[]>([]);

  const [selectedRow, setSelectedRow] = React.useState<any>();

  const [openCriarUsuario, setOpenCriarUsuario] = React.useState(false);

  const { user, isPending } = useLoggedUser();
  const { sistemaSelecionado } = useSistemaSelecionado();

  let showDelete = user?.user_role === "sup_admin" || user?.user_role === "admin" || user?.permissions?.includes("usuarios_deletar");

  const handleOnCellEditCommit = (params: any) => {
    setLoading(true);
    UsuariosRepo.updateField(params.id, params.field, params.value).then((response) => {
      Toast.success("Usuário atualizado com sucesso!");
    }).catch((error) => {
      Toast.error("Erro ao atualizar usuário!");
    }).finally(() => {
      setLoading(false);
      setRefresh(!refresh);
    });
  };

  const handleCriarUsuario = (usuario: Usuario) => {
    setLoading(true);
    UsuariosRepo.create(usuario.email, usuario.phone_number, usuario.user_role, usuario.can_access_clients, usuario.permissions, sistemaSelecionado ?? "").then((response) => {
      Toast.success("Usuário criado com sucesso!");
      setOpenCriarUsuario(false);
    }).catch((error) => {
      Toast.error("Erro ao criar usuário!");
    }).finally(() => {
      setLoading(false);
      setRefresh(!refresh);
    });
  }

  let columns: GridColDef[] = [
    {
      field: 'email', headerName: 'Email', flex: 800, editable: false,
      renderCell: (params: GridRenderCellParams<any, any>) => (
        <CustomTooltip title={params.row.email}>
          <span style={{
            display: 'inline-block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            padding: '10px'
          }}>
            {params.row.email}
          </span>
        </CustomTooltip>
      )
    },
    { field: 'phone_number', headerName: 'Telefone', flex: 800, editable: true, valueFormatter: (params) => phoneFormat(params.value) },
    {
      field: 'user_role', headerName: 'Cargo', flex: 800, editable: false,
      renderCell: (params: GridRenderCellParams<any, any>) => <SelectRole params={params} onCellEditCommit={handleOnCellEditCommit} />
    },
    {
      field: 'can_access_clients', headerName: 'Acesso', flex: 800, editable: false,
      renderCell: (params: GridRenderCellParams<any, any>) => (
        <Button
          sx={{ width: '-webkit-fill-available', height: '100%' }}
          variant="outlined"
          color="primary"
          onClick={() => { setSelectedRow(params.row); setSistemasAcessoSelectionados(params.row?.can_access_clients?.split(",") ?? []); setOpenEditAcesso(true) }}
        >
          {params.row.can_access_clients == "*" ? "Todos" : (params.row?.can_access_clients?.split(",")?.length ?? 0) + " Clientes"}
        </Button>
      ),
    },
    {
      field: 'permissions', headerName: 'Permissões', flex: 800, editable: false,
      renderCell: (params: GridRenderCellParams<any, any>) => (
        <Button
          sx={{ width: '-webkit-fill-available', height: '100%' }}
          variant="outlined"
          color="primary"
          onClick={() => { setSelectedRow(params.row); setOpenEditPermicoes(true) }}
        >
          {params.row.permissions == "*" ? "Todos" : (params.row.permissions?.split(",").length ?? 0) + " Permissões"}
        </Button>
      ),
    },
    {
      field: 'email_verified', headerName: 'E-mail Verificado', flex: 800,
      renderCell: (params) => (params.row.email_verified == "true" ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />)
    },
    {
      field: 'phone_number_verified', headerName: 'Telefone Verificado', flex: 800,
      renderCell: (params) => (params.row.phone_number_verified == "true" ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />)
    },

  ];

  if (showDelete) {
    columns.push({
      field: 'delete', headerName: 'Deletar', flex: 800, type: "actions",
      renderCell: (params) => (
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              if (user?.id === params.row.id) {
                Toast.error("Você não pode deletar seu próprio usuário!");
                return;
              }
              setLoading(true);
              UsuariosRepo.delete(params.row.id).then((response) => {
                Toast.success("Usuário deletado com sucesso!");
              }).catch((error) => {
                Toast.error("Erro ao deletar usuário!");
              }).finally(() => {
                setLoading(false);
                setRefresh(!refresh);
              });
            }}
            disabled={!showDelete}
          >
            Deletar
          </Button>
        </Stack>
      )
    });
  }

  React.useEffect(() => {
    setLoading(true);
    UsuariosRepo.getAll().then((response) => {
      setRows(response.data);
    }).catch((reason) => {
      if (reason.response.status === 403) {
        Toast.error("Você não tem permissão para acessar essa página!");
      }
    }).finally(() => {
      setLoading(false);
    });

  }, [refresh]);

  return (
    <>
      <TabelaCrud
        onRefreshClick={() => { setRefresh(!refresh) }}
        columns={columns}
        rows={rows}
        loading={loading}
        onCellEditCommit={handleOnCellEditCommit}
        onAddClick={() => setOpenCriarUsuario(true)}
      />

      <ModalAcessoClientes
        open={openEditAcesso}
        setOpen={setOpenEditAcesso}
        selectedRow={selectedRow}
        handleOnCellEditCommit={handleOnCellEditCommit}
        handleOnChangeSelect={(id: string, checked: boolean) => {
          if (checked) {
            setSistemasAcessoSelectionados([...sistemasAcessoSelectionados, id]);
          } else {
            setSistemasAcessoSelectionados(sistemasAcessoSelectionados.filter((item) => item !== id));
          }

        }}
        handleConfirm={() => {
          handleOnCellEditCommit({ id: selectedRow?.id, field: 'can_access_clients', value: sistemasAcessoSelectionados.includes("*") ? "*" : sistemasAcessoSelectionados.join(",") });
          setOpenEditAcesso(false);
        }}
      />

      <ModalCriarUsuario
        open={openCriarUsuario}
        setOpen={setOpenCriarUsuario}
        criarUsuario={handleCriarUsuario}
      />

      <ModalPermissoesUsuario
        open={openEditPermicoes}
        setOpen={setOpenEditPermicoes}
        inicialValue={selectedRow?.permissions}
        handleConfirm={(permissions: string) => {
          setOpenEditPermicoes(false);
          handleOnCellEditCommit({ id: selectedRow?.id, field: 'permissions', value: permissions });
        }}
      />

    </>
  );
}

function SelectRole(props: { params: GridRenderCellParams<any, any>, onCellEditCommit: Function }) {
  const [enabled, setEnabled] = React.useState(false);
  const { user } = useLoggedUser();

  return (
    <Select
      sx={{ width: '-webkit-fill-available' }}
      labelId="select-label"
      id="role"
      label="Cargo"
      disabled={!enabled}
      placeholder="Escolha um cargo"
      value={props.params.row?.user_role || ''}
      onDoubleClick={() => setEnabled(true)}
      onChange={(event) => {
        const newValue = event.target.value;
        setEnabled(false);
        props.onCellEditCommit({ id: props.params.id, field: 'user_role', value: newValue });
      }}
    >
      {["sup_admin"].includes(user?.user_role ?? "") ? <MenuItem value={"sup_admin"}>Super Admin</MenuItem> : null}
      {["admin", "sup_admin"].includes(user?.user_role ?? "") ? <MenuItem value={"admin"}>Admin</MenuItem> : null}
      <MenuItem value={"viewer"}>Analista</MenuItem>
    </Select>
  );
}

const phoneFormat = (input: any) => {
  if (!input || isNaN(input)) return `input must be a number was sent ${input}`
  if (typeof (input) !== 'string') input = input.toString()
  if (input.includes("+55")) input = input.replace("+55", "")
  if (input.length === 11) {
    return input.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
  } else if (input.length === 10) {
    return input.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
  } else if (input.length < 11) {
    return input
  } else if (input.length > 11) {
    return input
  } else {
    return input
  }
}