import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, FormControl, FormGroup, FormControlLabel, Checkbox, Button, DialogActions } from '@mui/material';
import { useLoggedUser } from '../../Contexts/LoggedUserContext';

type Props = {
  open: boolean,
  setOpen: Function,
  inicialValue: string,
  handleConfirm: (permissions: any) => void; // Modifique para aceitar as permissões como argumento
};

// Definição da interface de permissões
interface Permissions {
  criar: boolean;
  listar: boolean;
  editar: boolean;
  deletar: boolean;
}

// Estado inicial para as permissões de cada entidade
const initialPermissionsState: Record<string, Permissions> = {
  dispositivo: { criar: false, listar: false, editar: false, deletar: false },
  alarmes: { criar: false, listar: false, editar: false, deletar: false },
  grupoDispositivo: { criar: false, listar: false, editar: false, deletar: false },
  usuarios: { criar: false, listar: false, editar: false, deletar: false },
  provibAtivos: { criar: false, listar: false, editar: false, deletar: false },
  clientes: { criar: false, listar: false, editar: false, deletar: false },
};

export default function ModalPermissoesUsuario(props: Props) {
  const [permissions, setPermissions] = useState<Record<string, Permissions>>(initialPermissionsState);
  const { user } = useLoggedUser();

  useEffect(() => {
    if (!props.open) {
      setPermissions(initialPermissionsState);
      return;
    }

    const permissionsI: Record<string, Permissions> = { ...initialPermissionsState };
    props.inicialValue?.split(",").forEach((perm: string) => {
      const [entity, permission] = perm.split("_");
      if (entity && permission && permissionsI[entity]) {
        permissionsI[entity] = {
          ...permissionsI[entity],
          [permission]: true,
        };
      }
    });

    setPermissions(permissionsI);
  }, [props.open, props.inicialValue]);

  function handleChange(entity: string, permission: keyof Permissions, checked: boolean) {
    setPermissions(prevPermissions => ({
      ...prevPermissions,
      [entity]: {
        ...prevPermissions[entity],
        [permission]: checked,
      },
    }));
  }

  function handleConfirm() {
    const permissionsString = Object.entries(permissions)
      .flatMap(([entity, perms]) =>
        Object.entries(perms)
          .filter(([perm, value]) => value)
          .map(([perm]) => `${entity}_${perm}`)
      )
      .join(",");
    props.handleConfirm(permissionsString);
  }
  
  return (
    <Dialog
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Editar Permissões</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          {Object.entries(permissions).map(([entity, perms]) => (
            <CrudOptions
              key={entity}
              title={entity.charAt(0).toUpperCase() + entity.slice(1)}
              entity={entity}
              initialPermissions={perms}
              onChange={handleChange}
            />
          ))}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setOpen(false)} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function CrudOptions({ entity, initialPermissions, title, onChange }: { entity: string, initialPermissions: Permissions, title: string, onChange: (entity: string, permission: keyof Permissions, checked: boolean) => void }) {
  const permissionsArray: (keyof Permissions)[] = ["criar", "listar", "editar", "deletar"];
  const { user } = useLoggedUser();

  if (!user?.permissions?.includes('*') && !user?.permissions?.includes(entity)) {
    return <></>;
  }

  return (
    <FormGroup key={entity}>
      <h3>{title}</h3>
      {permissionsArray.map((perm) => (
        <FormControlLabel
          disabled={!user?.permissions?.includes(entity + "_" + perm) && !user?.permissions?.includes('*')}
          key={perm}
          control={<Checkbox checked={initialPermissions[perm]} onChange={(event, checked) => onChange(entity, perm, checked)} />}
          label={perm.charAt(0).toUpperCase() + perm.slice(1)}
        />
      ))}
    </FormGroup>
  );
}
