import React, { useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Chip } from "@mui/material";
import { Autocomplete } from "@mui/material";

interface ModalEditarPontosDeColetaProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  pontosDeColeta: string[];
  onConfirm: (pontosDeColeta: string[]) => void;
}

export default function ModalEditarPontosDeColeta({
  open,
  setOpen,
  pontosDeColeta,
  onConfirm,
}: ModalEditarPontosDeColetaProps) {
  const [values, setValues] = React.useState<string[]>(pontosDeColeta);

  // Atualiza o estado local sempre que os pontos de coleta externos mudam
  useEffect(() => {
    setValues(pontosDeColeta);
  }, [pontosDeColeta]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      <DialogTitle>Editar Pontos de Coleta</DialogTitle>
      <DialogContent>
        <Autocomplete
          multiple
          freeSolo
          options={[]} // Nenhuma opção pré-definida, permitindo a entrada livre
          value={values}
          onChange={(event, newValue) => setValues(newValue)}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" label={option} {...getTagProps({ index })} key={index} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Pontos de Coleta"
              placeholder="Digite e pressione Enter"
              margin="dense"
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancelar</Button>
        <Button
          onClick={() => {
            onConfirm(values);
            setOpen(false);
          }}
          color="primary"
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
