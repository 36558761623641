import React, { useEffect, useState } from 'react';
import { useSistemaSelecionado } from '../../../Contexts/BaseContext';
import ProvibAtivoRepo, { Ativo } from '../../../Services/ProvibAtivos';
import ProvibColetaRepo, { Coleta, RMS, FFT, FFTV, TWF } from '../../../Services/ProvibColetas';
import GlobalDataRepo from "../../../Services/GlobalDataRepo";
import { useQuery } from '@tanstack/react-query';
import Toast from '../../../Services/Toast';
import {
    AppBar,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Toolbar,
    Card,
    CardContent,
    Typography,
    Paper,
    Box
} from '@mui/material';
import DateTimeRangePicker from '../../../components/DateTimeRangePicker';
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    AreaChart,
    Area,
} from 'recharts';

export default function Ativos() {
    const { sistemaSelecionado } = useSistemaSelecionado();

    const [setores, setSetores] = useState<string[]>([]);
    const [setor, setSetor] = useState<string>("*");
    const [ativo, setAtivo] = useState<string>(window.localStorage.getItem('last_dispositivo_id') ?? "");
    const [selectedColeta, setSelectedColeta] = useState<string>("");

    let fromTs = parseInt(String(new Date().getTime() - 7 * 24 * 3600 * 1000));
    let toTs = parseInt(String(new Date().getTime()));
    const [dataFilter, setDataFilter] = useState<{ fromTs: number, toTs: number }>({ fromTs, toTs });

    const { data: ativos, isLoading: isLoadingAtivo, isFetching: isFetchingAtivos, error: errorAtivos } = useQuery({
        queryKey: ['ProvibAtivos', sistemaSelecionado],
        queryFn: () => ProvibAtivoRepo.list(sistemaSelecionado ?? "").catch(() => {
            Toast.error("Erro ao carregar Ativos");
        }),
        enabled: !!sistemaSelecionado,
    });

    const { data: coletas, isLoading: isLoadingColetas, isFetching: isFetchingColetas, error: errorColetas } = useQuery({
        queryKey: ['ProvibColetas', sistemaSelecionado, ativo, dataFilter],
        queryFn: () =>
            ProvibColetaRepo.list(
                ativo,
                parseInt(String(dataFilter.fromTs / 1000)),
                parseInt(String(dataFilter.toTs / 1000))
            ).catch(() => {
                Toast.error("Erro ao carregar Coletas");
            }),
        enabled: !!sistemaSelecionado && !!ativo && !!dataFilter.fromTs && !!dataFilter.toTs,
    });

    // const { data: rms, isLoading: isLoadingRMS, isFetching: isFetchingRMS, error: errorRMS } = useQuery({
    //     queryKey: ['ProvibColetasRMS', sistemaSelecionado, selectedColeta],
    //     queryFn: () => {
    //         let coleta_atual = coletas?.find((c: Coleta) => c.id === selectedColeta);
    //         if (!coleta_atual)
    //             return;

    //         return ProvibColetaRepo.get_file(
    //             coleta_atual.rms
    //         ).then(response => response as RMS).catch(() => {
    //             Toast.error("Erro ao carregar RMS");
    //         });
    //     },
    //     enabled: !!selectedColeta && !!coletas && coletas?.length > 0,
    // });

    const { data: dataGlobalData, isLoading: isLoadingGlobalData, isFetching: isFetchingGlobalData } = useQuery({
        queryKey: ['LatestGlobalData', sistemaSelecionado, ativo, selectedColeta],
        queryFn: () => {
            let coletaTs = coletas?.find((c: Coleta) => c.id === selectedColeta)?.ts ?? 0;
            let from = parseInt(String(coletaTs - 60));
            let to = parseInt(String(coletaTs + 60));
            return GlobalDataRepo.ListGlobalData(ativo!, from, to)
            .then(response => response.length > 0 ? response[0] : undefined)
            .catch(() => {
                Toast.error("Erro ao carregar Global Data");
            })
        }, 
        enabled: !!sistemaSelecionado && !!ativo && !!selectedColeta,
    });

    const { data: fft, isLoading: isLoadingFFT, isFetching: isFetchingFFT, error: errorFFT } = useQuery({
        queryKey: ['ProvibColetasFFT', sistemaSelecionado, selectedColeta],
        queryFn: () => {
            let coleta_atual = coletas?.find((c: Coleta) => c.id === selectedColeta);
            if (!coleta_atual)
                return;

            return ProvibColetaRepo.get_file(
                coleta_atual.fft
            ).then(response => response as FFT).catch(() => {
                Toast.error("Erro ao carregar FFT");
            });
        },
        enabled: !!selectedColeta && !!coletas && coletas?.length > 0,
    });

    const { data: fft_velocity, isLoading: isLoadingFFTV, isFetching: isFetchingFFTV, error: errorFFTV } = useQuery({
        queryKey: ['ProvibColetasFFTV', sistemaSelecionado, selectedColeta],
        queryFn: () => {
            let coleta_atual = coletas?.find((c: Coleta) => c.id === selectedColeta);
            if (!coleta_atual)
                return;

            return ProvibColetaRepo.get_file(
                coleta_atual.fft_velocity
            ).then(response => response as FFTV).catch(() => {
                Toast.error("Erro ao carregar FFT velocidade");
            });
        },
        enabled: !!selectedColeta && !!coletas && coletas?.length > 0,
    });

    const { data: twf, isLoading: isLoadingTWF, isFetching: isFetchingTWF, error: errorTWF } = useQuery({
        queryKey: ['ProvibColetasTWF', sistemaSelecionado, selectedColeta],
        queryFn: () => {
            let coleta_atual = coletas?.find((c: Coleta) => c.id === selectedColeta);
            if (!coleta_atual)
                return;

            return ProvibColetaRepo.get_file(
                coleta_atual.twf
            ).then(response => response as TWF).catch(() => {
                Toast.error("Erro ao carregar FFT velocidade");
            });
        },
        enabled: !!selectedColeta && !!coletas && coletas?.length > 0,
    });

    const dataAtual: Date = new Date();
    const dataDozeMesesAtras: Date = new Date(dataAtual);
    dataDozeMesesAtras.setMonth(dataAtual.getMonth() - 12);

    const { data: dataGlobalDataHist, isLoading: isLoadingGlobalDataHist, isFetching: isFetchingGlobalDataHist } = useQuery({
        queryKey: ['HistGlobalDataTendencia', sistemaSelecionado, ativo],
        queryFn: () => GlobalDataRepo.ListGlobalData(ativo!, parseInt(String(dataDozeMesesAtras.getTime() / 1000)), parseInt(String(dataAtual.getTime() / 1000))),
        enabled: !!sistemaSelecionado && !!ativo,
    });

    useEffect(() => {
        if (!ativo) return;

        ProvibColetaRepo.get_latest(ativo)
            .then((coleta) => {
                if (!coleta) return;
                console.log("Atualizado data");
                let dataColeta = new Date(coleta.ts * 1000);
                let startDayTs = new Date(dataColeta.getFullYear(), dataColeta.getMonth(), dataColeta.getDate()).getTime();
                let endDayTs = startDayTs + 24 * 3600 * 1000;

                setDataFilter({ fromTs: startDayTs, toTs: endDayTs });
                setSelectedColeta(coleta.id);
            })
            .catch(() => {
                Toast.error("Erro ao carregar Coleta mais recente");
            });
    }, [ativo]);

    if (isLoadingAtivo || isFetchingAtivos) {
        return <CircularProgress />;
    }

    if (errorAtivos) {
        return <>{errorAtivos}</>;
    }

    // Atualiza a lista de setores conforme os ativos carregados
    for (let ativoItem of ativos as Ativo[]) {
        if (!setores.includes(ativoItem.setor)) {
            setSetores([...setores, ativoItem.setor]);
        }
    }

    // Procura o objeto da coleta selecionada na lista de coletas
    const selectedColetaObj = coletas?.find((c: Coleta) => c.id === selectedColeta);

    let twfData: any[] | undefined = [];
    let fftData: any[] | undefined = [];
    let fftVData: any[] | undefined = [];

    if (!isLoadingTWF || !isFetchingTWF) {
        twfData = twf
            ? twf.time.map((time, index) => ({
                time, // você pode converter o timestamp para um formato legível, se preferir
                x: twf['x(g)'][index],
                y: twf['y(g)'][index],
                z: twf['z(g)'][index],
            }))
            : [];
    }

    // Transforma dados de FFT
    if (!isLoadingFFT || !isFetchingFFT) {
        fftData = fft
            ? fft.frequencies.map((frequency, index) => ({
                frequency,
                x: fft['x(g)'][index],
                y: fft['y(g)'][index],
                z: fft['z(g)'][index],
            }))
            : [];
    }

    // Transforma dados de FFTV
    if (!isLoadingFFTV || !isFetchingFFTV) {
        fftVData = fft_velocity
            ? fft_velocity.frequencies.map((frequency, index) => ({
                frequency,
                x: fft_velocity['x(mm/s)'][index],
                y: fft_velocity['y(mm/s)'][index],
                z: fft_velocity['z(mm/s)'][index],
            }))
            : [];
    }

    return (
        <>
            <AppBar
                elevation={0}
                position="static"
                sx={{ backgroundColor: 'white', borderBottom: '1px solid #ccc', padding: 1 }}
            >
                <Toolbar>
                    <FormControl variant="outlined" sx={{ minWidth: 120, margin: '0 10px' }}>
                        <InputLabel>Setor</InputLabel>
                        <Select value={setor} onChange={(e) => setSetor(e.target.value)} label="Setor">
                            <MenuItem value="*">Todos</MenuItem>
                            {isLoadingAtivo || isFetchingAtivos ? (
                                <MenuItem disabled>
                                    <CircularProgress size={24} />
                                </MenuItem>
                            ) : (
                                setores.map((setor, i) => (
                                    <MenuItem key={setor + i} value={setor}>
                                        {setor}
                                    </MenuItem>
                                ))
                            )}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" sx={{ minWidth: 120, margin: '0 10px' }}>
                        <InputLabel>Ativo</InputLabel>
                        <Select value={ativo} onChange={(e) => setAtivo(e.target.value)} label="Ativo">
                            <MenuItem value={""} disabled>
                                Selecione um ativo
                            </MenuItem>
                            {isLoadingAtivo || isFetchingAtivos ? (
                                <MenuItem disabled>
                                    <CircularProgress size={24} />
                                </MenuItem>
                            ) : (
                                ativos
                                    ?.filter((ativo: Ativo) => setor === "*" || ativo.setor === setor)
                                    .map((ativo: Ativo) => (
                                        <MenuItem key={ativo.id} value={ativo.id}>
                                            {ativo.nome + " - " + ativo.tag}
                                        </MenuItem>
                                    ))
                            )}
                        </Select>
                    </FormControl>
                    <DateTimeRangePicker
                        value={dataFilter}
                        onChange={(fromTs: number, toTs: number) => {
                            setDataFilter({ fromTs, toTs });
                        }}
                    />
                    <FormControl variant="outlined" sx={{ minWidth: 120, margin: '0 10px' }}>
                        <InputLabel>Coleta</InputLabel>
                        <Select
                            value={coletas?.length === 0 ? '' : selectedColeta}
                            onChange={(e) => setSelectedColeta(e.target.value)}
                            label="Coleta"
                        >
                            <MenuItem value={""} disabled>
                                Selecione uma coleta
                            </MenuItem>
                            {isLoadingColetas || isFetchingColetas ? (
                                <MenuItem disabled>
                                    <CircularProgress size={24} />
                                </MenuItem>
                            ) : (
                                coletas?.map((coleta: Coleta) => (
                                    <MenuItem key={coleta.id} value={coleta.id}>
                                        {coleta.ponto_coleta + " - " + new Date(coleta.ts * 1000).toLocaleString()}
                                    </MenuItem>
                                ))
                            )}
                        </Select>
                    </FormControl>
                </Toolbar>
            </AppBar>
            {selectedColetaObj && (
                <Card sx={{ margin: 2 }}>
                    <CardContent>
                        <Typography variant="h6" component="div" gutterBottom>
                            Detalhes da Coleta
                        </Typography>

                        <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            📌 <strong>Ponto de Coleta:</strong> {selectedColetaObj.ponto_coleta}
                        </Typography>
                        <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            ⚡ <strong>Frequência:</strong> {selectedColetaObj.frequency} Hz
                        </Typography>
                        <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            🧮 <strong>Sensibilidade:</strong> ±{selectedColetaObj.sensitivity} g
                        </Typography>
                        {isLoadingGlobalData || isFetchingGlobalData || dataGlobalData === undefined ? (
                            <CircularProgress />
                        ) : (
                            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                📊 <strong>Pico:</strong> {`x: ${dataGlobalData['Horizontal(g)'].toFixed(2)} g, y: ${dataGlobalData['Vertical(g)'].toFixed(2)} g, z: ${dataGlobalData['Axial(g)'].toFixed(2)} g`}
                            </Typography>
                        )}
                    </CardContent>
                </Card>
            )}

            <Box sx={{ height: 20 }} />

            {/* Gráfico TWF */}
            {twf && (
                <Box display={'flex'} minWidth={"800px"} minHeight={"350px"} height={"400px"}>
                    <Paper sx={{ width: '100%', margin: 1 }}>
                        <Typography variant="h6" align="center" component="div" gutterBottom>
                            TWF
                        </Typography>
                        <ResponsiveContainer width="100%" height={'100%'}>
                            <LineChart data={twfData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="time" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="x" stroke="#8884d8" dot={false} />
                                <Line type="monotone" dataKey="y" stroke="#82ca9d" dot={false} />
                                <Line type="monotone" dataKey="z" stroke="#ff7300" dot={false} />
                                <Tooltip />
                            </LineChart>
                        </ResponsiveContainer>
                    </Paper >
                </Box>
            )
            }
            <Box sx={{ height: 20 }} />

            {/* Gráfico FFT */}
            {fft && (
                <Box display={'flex'} minWidth={"800px"} minHeight={"350px"} height={"400px"}>
                    <Paper sx={{ width: '100%', margin: 1 }}>
                        <Typography variant="h6" align="center" component="div" gutterBottom>
                            FFT
                        </Typography>
                        <ResponsiveContainer width="100%" height={'100%'}>
                            <LineChart data={fftData}>
                                <XAxis dataKey="frequency" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="x" stroke="#8884d8" dot={false} />
                                <Line type="monotone" dataKey="y" stroke="#82ca9d" dot={false} />
                                <Line type="monotone" dataKey="z" stroke="#ff7300" dot={false} />
                                <Tooltip />
                            </LineChart>
                        </ResponsiveContainer>
                    </Paper>
                </Box>
            )}
            <Box sx={{ height: 20 }} />
            {/* Gráfico FFTV */}
            {fft_velocity && (
                <Box display={'flex'} minWidth={"800px"} minHeight={"350px"} height={"400px"}>
                    <Paper sx={{ width: '100%', margin: 1 }}>
                        <Typography variant="h6" align="center" component="div" gutterBottom>
                            FFT Velocidade
                        </Typography>
                        <ResponsiveContainer width="100%" height={'100%'}>
                            <LineChart data={fftVData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="frequency" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="x" stroke="#8884d8" dot={false} />
                                <Line type="monotone" dataKey="y" stroke="#82ca9d" dot={false} />
                                <Line type="monotone" dataKey="z" stroke="#ff7300" dot={false} />
                                <Tooltip />
                            </LineChart>
                        </ResponsiveContainer>
                    </Paper>
                </Box>
            )}

            <Box sx={{ height: 20 }} />
            {/* Gráfico Global Data */}
            {dataGlobalDataHist && (
                <Box display={'flex'} minWidth={"800px"} minHeight={"350px"} height={"400px"}>
                    <Paper sx={{ width: '100%', margin: 1 }}>
                        <Typography variant="h6" align="center" component="div" gutterBottom>
                            Tendência de Dados
                        </Typography>
                        <ResponsiveContainer width="100%" height={'100%'}>
                            <LineChart data={dataGlobalDataHist}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="ts" />
                                <YAxis />
                                <Tooltip />
                                <Legend />

                                {/* g */}
                                <Line type="monotone" dataKey="Horizontal(g)" stroke="#8884d8" fill="#8884d8" />
                                <Line type="monotone" dataKey="Vertical(g)" stroke="#82ca9d" fill="#82ca9d" />
                                <Line type="monotone" dataKey="Axial(g)" stroke="#ff7300" fill="#ff7300" />


                                {/* mm/s */}
                                <Line type="monotone" dataKey="Horizontal(mm/s)" stroke="#8884d8" fill="#8884d8" />
                                <Line type="monotone" dataKey="Vertical(mm/s)" stroke="#82ca9d" fill="#82ca9d" />
                                <Line type="monotone" dataKey="Axial(mm/s)" stroke="#ff7300" fill="#ff7300" />

                                <Tooltip />
                            </LineChart>
                        </ResponsiveContainer>
                    </Paper>
                </Box>
            )}
            <Box sx={{ height: 50 }} />
        </>
    );
}
