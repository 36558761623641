import React from "react";
import { Link, Paper } from '@mui/material';
import { Box, Stack, Typography } from "@mui/material";
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { DataGrid, GridRenderCellParams, GridSortModel } from "@mui/x-data-grid";
import GlobalDataRepo from "../../../Services/GlobalDataRepo";
import EventosRepo from "../../../Services/EventosRepo";
import { CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query'
import { useSistemaSelecionado } from "../../../Contexts/BaseContext";

export default function HomeDashboard() {
  const { sistemaSelecionado } = useSistemaSelecionado();

  return (
    <Box margin={2} height="100%" display="flex">
      <Box marginRight={2} marginBottom={2} width="60%" minWidth={"600px"}>
        <Stack height={'100%'}>
          <StatusConexaoGeral sistema_id={sistemaSelecionado} />
          <LatestGlobalDataTable sistema_id={sistemaSelecionado} />
        </Stack>
      </Box>
      <Box marginBottom={2} width={'40%'} minWidth={"500px"}>
        <LatestEventsDataTable sistema_id={sistemaSelecionado} />
      </Box>
    </Box>
  );
}

function StatusConexaoGeral(props: { sistema_id: string | null }) {
  const { isPending, error, data, isFetching } = useQuery({
    queryKey: ['StatusConexao', props.sistema_id],
    queryFn: () => GlobalDataRepo.StatusConexao(props.sistema_id ?? "").then((response) => response.data),
    enabled: !!props.sistema_id,
    placeholderData: { Online: 0, Atrasado: 0, Offline: 0 },
    refetchInterval: 120000,
    refetchIntervalInBackground: true,
  })

  if (isPending || isFetching) {
    return (
      <Paper sx={{ width: "100%", height: "25%" }} elevation={3}>
        <Box width={"100%"} height={"100%"} display="flex" justifyContent={'center'} alignItems={"center"}>
          <CircularProgress />
        </Box>
      </Paper>
    )
  }

  if (error) {
    return (
      <Paper sx={{ width: "100%", height: "25%" }} elevation={3}>
        <Box width={"100%"} height={"100%"} display="flex" justifyContent={'center'} alignItems={"center"}>
          <Typography color='black'>Ocorreu um erro: {error.message}</Typography>
        </Box>
      </Paper>
    )
  }

  if (data === null || Object.keys(data).length === 0) {
    return (
      <Paper sx={{ width: "100%", height: "25%" }} elevation={3}>
        <Box width={"100%"} height={"100%"} display="flex" justifyContent={'center'} alignItems={"center"}>
          <Typography color='black'>Nenhum dado encontrado</Typography>
        </Box>
      </Paper>
    )
  }

  let getGaugeColorOn = (value: number) => {
    if (value >= 15) return 'rgb(55, 135, 45)';
    if (value >= 14) return 'rgb(224, 180, 0)';

    return 'rgb(196, 22, 42)';
  };
  let getGaugeColorLate = (value: number) => {
    if (value >= 20) return 'rgb(196, 22, 42)';
    if (value >= 10) return 'rgb(224, 180, 0)';

    return 'rgb(55, 135, 45)';
  };
  let getGaugeColorOff = (value: number) => {
    if (value >= 15) return 'rgb(196, 22, 42)';
    if (value >= 10) return 'rgb(224, 180, 0)';

    return 'rgb(55, 135, 45)';
  };
  return (
    <Paper sx={{ width: "100%", height: "25%" }} elevation={3}>
      <Box width={"100%"} height={"100%"} display="flex">
        <StatusGauge label="Online" value={data.Online} getGugeColor={getGaugeColorOn} />
        <StatusGauge label="Atrasado" value={data.Atrasado} getGugeColor={getGaugeColorLate} />
        <StatusGauge label="Offline" value={data.Offline} getGugeColor={getGaugeColorOff} />
      </Box>
    </Paper>
  );
}

const StatusGauge = (props: { label: string, value: number, getGugeColor: Function }) => {
  const gaugeColor = props.getGugeColor ? props.getGugeColor(props.value) : props.value > 80 ? 'red' : props.value > 60 ? 'yellow' : 'green';
  return (

    <Box flexDirection="column" flexGrow={1} >
      <Box height={"80%"} width={"100%"}>
        <Gauge
          value={props.value}
          startAngle={-110}
          endAngle={110}
          sx={(theme) => ({
            [`& .${gaugeClasses.valueText}`]: {
              fontSize: 30,
              transform: 'translate(0px, 10px)',
            },
            [`& .${gaugeClasses.valueArc}`]: {
              fill: gaugeColor,
            },
            [`& .${gaugeClasses.referenceArc}`]: {
              fill: theme.palette.grey[300],
            },
          })}
          text={({ value }) => `${value}`}
          title={props.label}
        />
      </Box>
      <Typography variant="h6" align="center">{props.label}</Typography>
    </Box>
  );
}

function LatestGlobalDataTable(props: { sistema_id: string | null }) {
  const [sortModel, setSortModel] = React.useState<GridSortModel>([{ field: 'raw_ts', sort: 'desc' }]);

  const { isPending, error, data, isFetching } = useQuery({
    queryKey: ['LatestGlobalData', props.sistema_id],
    queryFn: () => GlobalDataRepo.LatestGlobalData(props.sistema_id ?? "").then((response) => response.data),
    enabled: !!props.sistema_id,
    refetchInterval: 120000,
    refetchIntervalInBackground: true,
    placeholderData: [],
  })

  const columnsGlobalData = [
    {
      field: 'raw_ts', headerName: 'Data/Hora', flex: 800,
      renderCell: (params: GridRenderCellParams<any, any>) => {
        return <Typography variant="body2">{new Date(parseInt(params.value) * 1000).toLocaleString("pt-BR")}</Typography>
      }
    },
    { field: 'tag', headerName: 'Tag', flex: 500 },
    {
      field: 'dispositivo_id', headerName: 'Dispositivo', flex: 600,
      renderCell: (params: GridRenderCellParams<any, any>) => {
        return <Link onClick={() => { window.localStorage.setItem("last_dispositivo_id", params.value) }} href="/analise" variant="body2">{params.value}</Link>
      }
    },
    { field: 'Bateria', headerName: 'Bateria', flex: 400 },
    { field: 'Estado', headerName: 'Estado', flex: 400 },
    { field: 'Horizontal(g)', headerName: 'Horizontal(g)', flex: 500 },
    { field: 'Vertical(g)', headerName: 'Vertical(g)', flex: 500 },
    { field: 'Axial(g)', headerName: 'Axial(g)', flex: 500 },
  ];

  return <DataGrid
    sx={{ marginTop: 2 }}
    loading={isFetching || isPending}
    error={error}
    autoPageSize={true}
    rows={data || []}
    getRowId={(row) => row.dispositivo_id}
    columns={columnsGlobalData}
    disableSelectionOnClick
    onSortModelChange={(model) => setSortModel(model)}
    sortModel={sortModel}
    localeText={{
      'noRowsLabel': 'Nenhum dado encontrado',
    }}
  />
}

function LatestEventsDataTable(props: { sistema_id: string | null }) {
  const [sortModelEvt, setSortModelEvt] = React.useState<GridSortModel>([{ field: 'raw_ts', sort: 'desc' }]);

  const { isPending, error, data, isFetching } = useQuery({
    queryKey: ['LatestEventsData', props.sistema_id],
    queryFn: () => EventosRepo.GetLatestByClientId(props.sistema_id ?? "", 20).then((response) => response.data),
    enabled: !!props.sistema_id,
    refetchInterval: 120000,
    refetchIntervalInBackground: true,
    placeholderData: []
  })

  const columnsEventos = [
    {
      field: 'raw_ts', headerName: 'Data/Hora', flex: 800,
      renderCell: (params: GridRenderCellParams<any, any>) => {
        return <Typography variant="body2">{new Date(parseInt(params.value) * 1000).toLocaleString("pt-BR")}</Typography>
      }
    },
    { field: 'evento', headerName: 'Evento', flex: 800 },
    { field: 'tag', headerName: 'Tag', flex: 500 },
    { field: 'dispositivo_id', headerName: 'Dispositivo', flex: 800,
      renderCell: (params: GridRenderCellParams<any, any>) => {
        return <Link onClick={() => { window.localStorage.setItem("last_dispositivo_id", params.value) }} href="/analise" variant="body2">{params.value}</Link>
      }
    },
    { field: 'criticidade', headerName: 'Criticidade', flex: 500 },
  ];


  return <DataGrid
    loading={isPending || isFetching}
    autoPageSize={true}
    error={error}
    rows={data || []}
    columns={columnsEventos}
    disableSelectionOnClick
    onSortModelChange={(model) => setSortModelEvt(model)}
    sortModel={sortModelEvt}
    localeText={{
      'noRowsLabel': 'Nenhum dado encontrado',
    }}
  />
}