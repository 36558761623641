import api from './Api';

interface Cliente {
    id: string;
    nome: string;
    contrato: string;
}

interface ClienteCreate {
    nome: string;
    contrato: "ATIVO" | "INATIVO" | "CANCELADO";
}

export type { Cliente };

export default {
    getAll: async () => {
        return api.get(`/cliente`).then(res => res.data);
    },
    create: async (cliente: ClienteCreate) => {
        return api.post(`/cliente`, cliente).then(res => res.data);
    },
    update: async (cliente: Cliente) => {
        return api.put(`/cliente`, cliente).then(res => res.data);
    },
    delete: async (id: string) => {
        return api.delete(`/cliente?id=${id}`).then(res => res.data);
    }
}