import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";

export default function ModalCriarCliente(props: { open: boolean, setOpen: Function, criarCliente: (nome: string, contrato: string) => void }) {

    const [name, setName] = React.useState("");
    const [contrato, setContrato] = React.useState("");

    return (
        <Dialog
            open={props.open}
            onClose={() => props.setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Criar Cliente</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Para criar um novo cliente, preencha os campos abaixo.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Nome"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    error={name.length < 1}
                    fullWidth
                />
                <FormControl fullWidth margin="dense">
                    <InputLabel id="select-label">Tipo</InputLabel>
                    <Select
                        labelId="select-label"
                        id="select"
                        label="Contrato"
                        value={contrato}
                        onChange={(e) => setContrato(e.target.value)}
                        required
                        error={contrato.length < 1}
                    >
                        <MenuItem value={"ATIVO"}>ATIVO</MenuItem>
                        <MenuItem value={"INATIVO"}>INATIVO</MenuItem>
                        <MenuItem value={"CANCELADO"}>CANCELADO</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setOpen(false)} color="primary">
                    Cancelar
                </Button>
                <Button onClick={() => { props.criarCliente(name, contrato); }} color="primary" autoFocus>
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
}