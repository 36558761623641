import React, { SyntheticEvent } from 'react';
import { DateRangePicker } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import { DateRange } from 'rsuite/esm/DateRangePicker/types';
import ptBrLocale from 'rsuite/locales/pt_BR';


  
export default function DateTimeRangePicker(props : { onChange: Function, value: { fromTs: number, toTs: number } }) {
    let value: DateRange = [new Date(props.value.fromTs), new Date(props.value.toTs)];

    function handleChange(value: DateRange | null, event: SyntheticEvent<Element, Event>) {
        let startTs = value?.[0].getTime();
        let endTs = value?.[1].getTime();
        props.onChange(startTs, endTs);
    }
    return (
        
        <DateRangePicker
            size='lg'
            value={value}
            onChange={handleChange}
            placeholder={'Data Inicial, Data Final'}
            format="yyyy-MM-dd HH:mm:ss"
            defaultCalendarValue={[new Date('2022-02-01 00:00:00'), new Date('2022-03-01 23:59:59')]}
            locale={ptBrLocale.DateRangePicker}
            showOneCalendar
            appearance="subtle"
        />
    );
}
