import { AppBar, Box, MenuItem, Select, Toolbar, Typography } from '@mui/material';
import { Data } from 'plotly.js';
import React from 'react';
import Plot from 'react-plotly.js';
import GlobalDataRepo from '../../../Services/GlobalDataRepo';
import moment from 'moment';
import DateTimeRangePicker from '../../../components/DateTimeRangePicker';
import { useQuery } from '@tanstack/react-query';
import DispositivoRepo from '../../../Services/DispositivoRepo';
import { useSistemaSelecionado } from '../../../Contexts/BaseContext';

let colors: any = [
  { stroke: "rgb(138, 184, 255)", fill: "rgb(138, 184, 255, 0.3)" },
  { stroke: "rgb(242, 204, 12)", fill: "rgb(242, 204, 12, 0.3)" },
  { stroke: "rgb(115, 191, 105)", fill: "rgb(115, 191, 105, 0.3)" }
];

export default function CascataDash() {
  const fromTs = parseInt(String((new Date().getTime()) - (6 * 3600 * 1000)));
  const toTs = parseInt(String(new Date().getTime()));

  const { sistemaSelecionado } = useSistemaSelecionado();
  const [dispositivoSelected, setDispositivoSelected] = React.useState<string>(window.localStorage.getItem('last_dispositivo_id') ?? "");
  const [dataFilter, setDataFilter] = React.useState<DataFilter>({ fromTs, toTs });
  const [mode, setMode] = React.useState<string>("acc");

  return (
    <>
      <FiltersAppBar
        sistemaSelecionado={sistemaSelecionado}
        dispositivoSelected={dispositivoSelected}
        setDispositivoSelected={setDispositivoSelected}
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        mode={mode}
        setMode={setMode}
      />
      <Cascata
        dispositivoSelected={dispositivoSelected}
        dataFilter={dataFilter}
        mode={mode}
      />
    </>
  );
}

function processData(rawData: GlobalData[], unidade: string) {
  let groupedData: any = {};

  rawData.forEach((d) => {
    let rowDate = new Date(parseInt(d.raw_ts) * 1000);
    let date = `${rowDate.getFullYear()}-${rowDate.getMonth() + 1}-${rowDate.getDate()}`;

    if (!groupedData[date]) {
      groupedData[date] = {
        "hour": [],
        "date": [],
        "ts": [],
        [`Vertical(${unidade})`]: [],
        [`Horizontal(${unidade})`]: [],
        [`Axial(${unidade})`]: []
      };
    }

    let newDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), rowDate.getHours(), rowDate.getMinutes(), rowDate.getSeconds());
    groupedData[date][`Vertical(${unidade})`].push(d[`Vertical(${unidade})`]);
    groupedData[date][`Horizontal(${unidade})`].push(d[`Horizontal(${unidade})`]);
    groupedData[date][`Axial(${unidade})`].push(d[`Axial(${unidade})`]);
    groupedData[date]["hour"].push(newDate);
    groupedData[date]["date"].push(rowDate.toDateString());
    groupedData[date]["ts"].push(parseInt(d.raw_ts) * 1000);
  });

  return groupedData;
}

function Cascata({ dispositivoSelected, dataFilter, mode }: { dispositivoSelected: string, dataFilter: DataFilter, mode: string }) {
  let unidade = mode === "acc" ? "g" : "mm/s";
  const { isPending, error, data: rawData, isFetching } = useQuery<GlobalData[], Error>({
    queryKey: ['HistoricoDadosGlobais', dispositivoSelected, dataFilter.fromTs, dataFilter.toTs],
    queryFn: () => GlobalDataRepo.ListGlobalData(dispositivoSelected, parseInt(String(dataFilter.fromTs / 1000)), parseInt(String(dataFilter.toTs / 1000))),
    enabled: !!dispositivoSelected,
  });

  if (isPending || isFetching) return <Typography color='black'>Carregando...</Typography>;
  if (error) {
    if ((error as any).response?.status === 403)
      return <Typography color='black'>Você precisa ter permissão para listar dados</Typography>;
    return <Typography color='black'>Ocorreu um erro: {error.message}</Typography>;
  }
  if (!rawData) return <Typography color='black'>Nenhum dado encontrado</Typography>;

  let data = rawData.sort((a, b) => parseInt(a.raw_ts) - parseInt(b.raw_ts));
  let groupedData = processData(data, unidade);

  let plotData: any = [];
  let yaxis_ticktext: any[] = [];

  Object.keys(groupedData).forEach((date) => {
    let traces = ['Vertical', 'Horizontal', 'Axial'].map((axis, index) => ({
      x: groupedData[date]["date"],
      y: groupedData[date]["hour"],
      z: groupedData[date][`${axis}(${unidade})`],
      mode: 'lines',
      type: 'scatter3d',
      name: `${axis}(${unidade})`,
      line: { color: colors[index].stroke }
    }));
    yaxis_ticktext.push(...groupedData[date]["hour"].map((hourTs: moment.MomentInput) => moment(hourTs).format('HH:mm')));
    plotData.push(...traces);
  });

  const layout = {
    title: '',
    autosize: true,
    showlegend: false,
    scene: {
      xaxis: { title: 'Dia' },
      yaxis: { title: 'Hora' },
      zaxis: { title: 'Amplitude' },
      yaxis_ticktext: yaxis_ticktext
    }
  };

  return (
    <Box height={"100%"} width={"100%"}>
      <Plot useResizeHandler style={{ width: "100%", height: "100%" }} data={plotData} layout={layout} />
    </Box>
  );
}

function FiltersAppBar({ mode, setMode, sistemaSelecionado, dispositivoSelected, setDispositivoSelected, dataFilter, setDataFilter }: FiltersAppBarProps) {
  return (
    <AppBar position="static" sx={{ backgroundColor: '#fff' }} elevation={0}>
      <Toolbar>
        <DispositivoPicker
          sistemaSelecionado={sistemaSelecionado}
          dispositivoSelected={dispositivoSelected}
          setDispositivoSelected={setDispositivoSelected}
        />
         <Select value={mode} sx={{ ml: 2 }}
          onChange={(event) => setMode(event.target.value as string)}>
          <MenuItem value="acc">Aceleração</MenuItem>
          <MenuItem value="vel">Velocidade</MenuItem>
        </Select>
        <Box flexGrow={1}></Box>
        <DateTimeRangePicker value={dataFilter} onChange={(fromTs: number, toTs: number) => setDataFilter({ fromTs, toTs })} />
      </Toolbar>
    </AppBar>
  );
}

function DispositivoPicker({ sistemaSelecionado, dispositivoSelected, setDispositivoSelected }: DispositivoPickerProps) {
  const { isPending, error, data, isFetching } = useQuery({
    queryKey: ['CadastroDispositivos', sistemaSelecionado],
    queryFn: () => DispositivoRepo.getByCliente(sistemaSelecionado ?? ""),
    enabled: !!sistemaSelecionado,
    retry: 0
  });

  if (isPending || isFetching) return <Typography color='black'>Carregando...</Typography>;
  if (error) {
    if ((error as any).response?.status === 403)
      return (
        <Select disabled value={"0"}>
          <MenuItem value="0" disabled>
            Você precisa ter permissão para listar dispositivos
          </MenuItem>
        </Select>
      );
    return <Typography color='black'>Ocorreu um erro: {error.message}</Typography>;
  }

  return (
    <Select
      value={dispositivoSelected}
      onChange={(event) => {
        setDispositivoSelected(event.target.value);
        window.localStorage.setItem('last_dispositivo_id', event.target.value ?? "");
      }}
      displayEmpty
      inputProps={{ 'aria-label': 'Without label' }}
    >
      <MenuItem value="" disabled>
        Escolha um dispositivo
      </MenuItem>
      {data && data.map((dispositivo, index) => (
        <MenuItem key={index} value={dispositivo.id}>
          {dispositivo.nome}
        </MenuItem>
      ))}
    </Select>
  );
}


// Define the types for your data
interface GlobalData {
  raw_ts: string;
  [key: string]: any; // This allows for other dynamic properties
}

interface DataFilter {
  fromTs: number;
  toTs: number;
}

// Define a type for the properties of FiltersAppBar
interface FiltersAppBarProps {
  mode: string;
  setMode: React.Dispatch<React.SetStateAction<string>>;
  sistemaSelecionado: string | null;
  dispositivoSelected: string;
  setDispositivoSelected: React.Dispatch<React.SetStateAction<string>>;
  dataFilter: DataFilter;
  setDataFilter: React.Dispatch<React.SetStateAction<DataFilter>>;
}

// Define a type for the properties of DispositivoPicker
interface DispositivoPickerProps {
  sistemaSelecionado: string | null;
  dispositivoSelected: string;
  setDispositivoSelected: React.Dispatch<React.SetStateAction<string>>;
}
