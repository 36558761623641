import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { AppBar, Box, Toolbar, Select, MenuItem, Typography, Paper } from '@mui/material';
import DateTimeRangePicker from '../../../components/DateTimeRangePicker';
import DispositivoRepo from '../../../Services/DispositivoRepo';
import { Gauge, gaugeClasses } from '@mui/x-charts';
import { CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Area, AreaChart } from 'recharts';
import GlobalDataRepo from '../../../Services/GlobalDataRepo';
import { useSistemaSelecionado } from '../../../Contexts/BaseContext';

export default function Analise() {
    let fromTs = parseInt(String((new Date().getTime()) - (6 * 3600 * 1000)));
    let toTs = parseInt(String(new Date().getTime()));
    const [dataFilter, setDataFilter] = React.useState<{ fromTs: number, toTs: number }>({ fromTs, toTs }); // default last 6 hours

    const { sistemaSelecionado } = useSistemaSelecionado();
    const [dispositivoSelected, setDispositivoSelected] = React.useState<string>(window.localStorage.getItem('last_dispositivo_id') ?? "");

    return (
        <>
            <FiltersAppBar
                dispositivoSelected={dispositivoSelected}
                setDispositivoSelected={setDispositivoSelected}
                sistemaSelecionado={sistemaSelecionado}
                setDataFilter={setDataFilter}
                dataFilter={dataFilter}
            />
            <Box display="flex" height={"30%"} padding={1} minHeight={"200px"} minWidth={"800px"}>
                <BateriaGauge dispositivoSelected={dispositivoSelected} />
                <LatestDeviveData dispositivoSelected={dispositivoSelected} />
            </Box>
            <Typography variant="h6" align="center" style={{ fontSize: '1.5vw' }}>Aceleração</Typography>
            <Box display={'flex'} minWidth={"800px"} minHeight={"350px"} height={"100%"}>
                <LineChartComponent
                    dataFilter={dataFilter}
                    dispositivoSelected={dispositivoSelected}
                    columns={['Vertical(g)', 'Horizontal(g)', 'Axial(g)']}
                />
            </Box>
            <Typography variant="h6" align="center" style={{ fontSize: '1.5vw' }}>Velocidade</Typography>
            <Box display={'flex'} minWidth={"800px"} minHeight={"350px"} height={"100%"}>
                <LineChartComponent
                    dataFilter={dataFilter}
                    dispositivoSelected={dispositivoSelected}
                    columns={['Vertical(mm/s)', 'Horizontal(mm/s)', 'Axial(mm/s)']}
                />
            </Box>
        </>
    );
}

function FiltersAppBar(props: { sistemaSelecionado: string | null, dispositivoSelected: string, setDispositivoSelected: Function, setDataFilter: Function, dataFilter: { fromTs: number, toTs: number } }) {
    return <>
        <AppBar position="static" sx={{ backgroundColor: '#fff' }} elevation={0}>
            <Toolbar>
                <DispositivoPicker
                    sistemaSelecionado={props.sistemaSelecionado}
                    dispositivoSelected={props.dispositivoSelected}
                    setDispositivoSelected={props.setDispositivoSelected}
                />
                <Box flexGrow={1}></Box>
                <DateTimeRangePicker value={props.dataFilter} onChange={(fromTs: number, toTs: number) => { props.setDataFilter({ fromTs, toTs }) }} />
            </Toolbar>
        </AppBar>
    </>;
}

let colors: any = [
    { stroke: "rgb(138, 184, 255)", fill: "rgb(138, 184, 255, 0.3)" },
    { stroke: "rgb(242, 204, 12)", fill: "rgb(242, 204, 12, 0.3)" },
    { stroke: "rgb(115, 191, 105)", fill: "rgb(115, 191, 105, 0.3)" }
];
function LineChartComponent(props: { dispositivoSelected: string, dataFilter: { fromTs: number, toTs: number }, columns: string[] }) {
    let fromTs = parseInt(String(props.dataFilter.fromTs / 1000));
    let toTs = parseInt(String(props.dataFilter.toTs / 1000));

    const { isPending, error, data, isFetching } = useQuery({
        queryKey: ['HistoricoDadosGlobais', props.dispositivoSelected, fromTs, toTs],
        queryFn: () => GlobalDataRepo.ListGlobalData(props.dispositivoSelected, fromTs, toTs),
        enabled: !!props.dispositivoSelected
    });

    if (isPending || isFetching) return <Typography color='black'>Carregando...</Typography>;
    if (error) return <Typography color='black'>Ocorreu um erro: {error.message}</Typography>;

    data.sort((a: any, b: any) => a.raw_ts - b.raw_ts);

    return (
        <Paper sx={{ width: '100%', margin: 1}}>
            <ResponsiveContainer width="100%" height={'100%'}>
                <AreaChart data={data}>
                    {props.columns.map((column, index) => (
                        <Area
                            key={index}
                            type="monotone"
                            dataKey={column}
                            stroke={colors[index].stroke}
                            fill={colors[index].fill}
                            dot={({ cx, cy, payload, index }) => payload.Estado.includes('Impacto Detectado') ? <circle cx={cx} cy={cy} r={4} fill="red" /> : <svg key={`vertical-${index}`} />}
                        />
                    ))}
                    <CartesianGrid stroke="#ccc" />
                    <XAxis dataKey="ts" />
                    <YAxis />
                    <Tooltip />
                </AreaChart>
            </ResponsiveContainer>
        </Paper>
    );
}

function DispositivoPicker(props: { sistemaSelecionado: string | null, dispositivoSelected: string, setDispositivoSelected: Function }) {
    const { isPending, error, data, isFetching } = useQuery({
        queryKey: ['CadastroDispositivos', props.sistemaSelecionado],
        queryFn: () => DispositivoRepo.getByCliente(props.sistemaSelecionado ?? ""),
        staleTime: 120000,
        enabled: !!props.sistemaSelecionado,
        retry: 0
    });

    if (isPending || isFetching) return <Typography color='black'>Carregando...</Typography>;
    if (error) {
        if ((error as any).response?.status === 403)
            return (
                <Select disabled value={"0"}>
                    <MenuItem value="0" disabled>
                        Você precisa ter permissão para listar dispositivos
                    </MenuItem>
                </Select>
            );
        return <Typography color='black'>Ocorreu um erro: {error.message}</Typography>;
    }

    return (
        <Select
            value={props.dispositivoSelected}
            onChange={
                (value) => {
                    props.setDispositivoSelected(value.target.value?.toString());
                    window.localStorage.setItem('last_dispositivo_id', value.target.value?.toString() ?? "");
                }
            }
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
        >
            <MenuItem value="" disabled>
                Ecolha um dispositivo
            </MenuItem>
            {data && data.map((dispositivo, index) => (
                <MenuItem key={index} value={dispositivo.id}>
                    {dispositivo.nome}
                </MenuItem>
            ))}
        </Select>
    );
}

function BateriaGauge(props: { dispositivoSelected: string }) {
    const { isPending, error, data, isFetching } = useQuery({
        queryKey: ['LatestGlobalData', props.dispositivoSelected],
        queryFn: () => GlobalDataRepo.LatestGlobalDataByDispositivo(props.dispositivoSelected),
        refetchInterval: 120000,
        refetchIntervalInBackground: true,
        enabled: !!props.dispositivoSelected,
    });

    if (isPending || isFetching) return <Typography color='black'>Carregando...</Typography>;
    if (error) return <Typography color='black'>Ocorreu um erro: {error.message}</Typography>;
    if (!data || (data as any).length === 0) return <Typography color='black'>Nenhum dado encontrado</Typography>;

    function getGAugeColor(value: number) {
        return value >= 60 ? 'green' : value > 40 ? 'yellow' : 'red';
    }

    return (
        <Paper sx={{ width: '20%', marginRight: 1 }}>
            <Box width={"100%"} height={"100%"} display="flex">
                <CustomGauge
                    label="Bateria"
                    value={data?.Bateria ?? -1} // Ensure this is a number
                    maxValue={100}
                    getGugeColor={getGAugeColor}
                />
            </Box>
        </Paper>
    );
}

function LatestDeviveData(props: { dispositivoSelected: string }) {
    const { isPending, error, data, isFetching } = useQuery({
        queryKey: ['LatestGlobalData', props.dispositivoSelected],
        queryFn: () => GlobalDataRepo.LatestGlobalDataByDispositivo(props.dispositivoSelected),
        refetchInterval: 120000,
        refetchIntervalInBackground: true,
        enabled: !!props.dispositivoSelected
    });

    if (isPending || isFetching) return <Typography color='black'>Carregando...</Typography>;
    if (error) return <Typography color='black'>Ocorreu um erro: {error.message}</Typography>;
    if (!data || (data as any).length === 0) return <Typography color='black'>Nenhum dado encontrado</Typography>;

    let getGugeColorG = (value: number) => {
        if (value >= 6) return 'rgb(143, 59, 184)';
        if (value >= 4.5) return 'rgb(196, 22, 42)';
        if (value >= 1.05) return 'rgb(224, 180, 0)';
        return 'rgb(55, 135, 45)';
    };

    let getGugeColorMMS = (value: number) => {
        if (value >= 10) return 'rgb(143, 59, 184)';
        if (value >= 6) return 'rgb(196, 22, 42)';
        if (value >= 4.5) return 'rgb(224, 180, 0)';
        return 'rgb(55, 135, 45)';
    };

    return (
        <Paper sx={{ width: '80%' }}>
            <Box width={"100%"} height={"100%"} display="flex">
                <CustomGauge label="Vertical(g)" value={data['Vertical(g)']} getGugeColor={getGugeColorG} />
                <CustomGauge label="Horizontal(g)" value={data['Horizontal(g)']} getGugeColor={getGugeColorG} />
                <CustomGauge label="Axial(g)" value={data['Axial(g)']} getGugeColor={getGugeColorG} />
                <CustomGauge label="Vertical(mm/s)" value={data['Vertical(mm/s)']} getGugeColor={getGugeColorMMS} />
                <CustomGauge label="Horizontal(mm/s)" value={data['Horizontal(mm/s)']} getGugeColor={getGugeColorMMS} />
                <CustomGauge label="Axial(mm/s)" value={data['Axial(mm/s)']} getGugeColor={getGugeColorMMS} />
            </Box>
        </Paper>
    );
}

const CustomGauge = (props: { label: string, value: number, getGugeColor: Function, maxValue?: number }) => {
    const gaugeColor = props.getGugeColor(props.value); // Calculate the gauge color based on the value

    return (
        <Box flexDirection="column" flexGrow={1}>
            <Box height={"80%"} width={"100%"}>
                <Gauge
                    valueMin={0}
                    valueMax={props.maxValue || props.value + 2} // Update valueMax to match your battery percentage scale
                    value={props.value}
                    startAngle={-110}
                    endAngle={110}
                    sx={{
                        [`& .${gaugeClasses.valueText}`]: {
                            fontSize: '1.5rem',
                            transform: 'translate(0px, 10px)',
                        },
                        [`& .${gaugeClasses.valueArc}`]: {
                            fill: gaugeColor,
                        },
                        [`& .${gaugeClasses.referenceArc}`]: {
                            fill: '#ccc', // Changed from theme.palette.grey[300] to a fixed color for simplicity
                        },
                    }}
                    text={({ value }) => `${value?.toFixed(2)}`}
                    title={props.label}
                />
            </Box>
            <Typography width={"100%"} variant="h6" align="center" style={{ fontSize: '1vw' }}>{props.label}</Typography>
        </Box>
    );
}
