import api from './Api';

interface Coleta {
    id: string;
    ativo: string;
    date: string;
    fft: string;
    fft_velocity: string;
    frequency: string;
    ponto_coleta: string;
    rms: string;
    sensitivity: string;
    ts: number;
    twf: string;
}


interface RMS {
    RMS: { "x(g)": number, "y(g)": number, "z(g)": number };
}

interface FFT {
    frequencies: number[];
    "x(g)": number[];
    "y(g)": number[];
    "z(g)": number[];
}

interface FFTV {
    frequencies: number[];
    "x(mm/s)": number[];
    "y(mm/s)": number[];
    "z(mm/s)": number[];
}

interface TWF {
    time: number[];
    'x(g)': number[];
    'y(g)': number[];
    'z(g)': number[];
}

export type { Coleta, RMS, FFT, FFTV, TWF };

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: async (ativoId: string, fromTs: number, toTs: number): Promise<Coleta[]> => {
        return api.get(`/provib_coletas_manuais?ativo=${ativoId}&tsFrom=${fromTs}&tsTo=${toTs}`).then(response => response.data);
    },
    get_latest: async (ativoId: string): Promise<Coleta> => {
        return api.get(`/provib_coletas_manuais/latest?ativo=${ativoId}`).then(response => response.data);
    },
    get_file: async (fileUrl: string): Promise<any> => {
        return api.get(`/provib_coletas_manuais/get_s3_content?key=${fileUrl}`).then(response => response.data);
    },
}