import React, { MouseEventHandler } from "react";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import ClienteRepo, { Cliente } from "../../Services/ClienteRepo";
import { useQuery } from "@tanstack/react-query";

interface Props {
  open: boolean,
  setOpen: Function,
  handleOnChangeSelect: Function,
  handleOnCellEditCommit: Function,
  selectedRow: any,
  handleConfirm: MouseEventHandler<HTMLButtonElement> | undefined;
};
export default function ModalAcessoClientes(props: Props) {
  return (
    <Dialog
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Editar Acesso</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <FormGroup>
            <ClientesSelect selectedRow={props.selectedRow} handleOnChangeSelect={props.handleOnChangeSelect}  />
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setOpen(false)} color="primary">
          Cancelar
        </Button>
        <Button onClick={props.handleConfirm} color="primary" autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ClientesSelect(props: { selectedRow: any, handleOnChangeSelect: Function }) {
  const { isPending, error, data, isFetching } = useQuery({
    queryKey: ['SistemasList'],
    queryFn: () => ClienteRepo.getAll(),
    staleTime: Infinity,
    placeholderData: [],
  })

  if (isPending || isFetching) return <p>Loading...</p>
  if (error) return <p>Error: {error.message}</p>


  let clientes: Cliente[] = Array.from([{ id: "*", nome: "Todos", contrato: "" }]);
  clientes = clientes.concat(data);

  return <>
    {clientes.map((cliente: Cliente) => (
      <FormControlLabel
        key={cliente.id}
        control={
          <Checkbox
            name={cliente.nome}
            defaultChecked={props.selectedRow?.can_access_clients?.split(',')?.includes(cliente.id)}
            onChange={
              (evt: any, checked: boolean) => {
                props.handleOnChangeSelect(cliente.id, checked);
              }
            }
          />
        }
        label={cliente.nome}
      />
    ))}
  </>
}