import React from "react";
import { useState } from "react";
import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, IconButton, Select, MenuItem, AppBar, Toolbar, Typography } from "@mui/material";
import DnsRoundedIcon from "@mui/icons-material/DnsRounded";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import LogoutIcon from '@mui/icons-material/Logout';
import SpeedIcon from '@mui/icons-material/Speed';
import ClienteRepo, { Cliente } from "../../Services/ClienteRepo";
import { useQuery } from '@tanstack/react-query';
import { useSistemaSelecionado } from "../../Contexts/BaseContext";
import { useLoggedUser } from "../../Contexts/LoggedUserContext";
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';

interface props {
  actual_page: string;
}
const item = {
  py: "2px",
  px: 3,
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover, &:focus": {
    bgcolor: "rgba(255, 255, 255, 0.08)",
  },
};

const itemCategory = {
  boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  py: 1.5,
  px: 3,
};
export default function Navigator({ actual_page }: props) {

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const { sistemaSelecionado, setSistemaSelecionado } = useSistemaSelecionado();

  const { user, isPending } = useLoggedUser();

  let userRole = user?.user_role ?? "viewer";

  var categories: any = [
    {
      id: "Dashboards",
      children: [
        {
          id: "Home Dashboard",
          label: "Home",
          icon: <SpeedIcon />,
          active: actual_page === "HomeDash",
          route: "/home",
        },
        {
          id: "Gestão a Vista",
          label: "Gestão a Vista - App",
          icon: <SpeedIcon />,
          active: actual_page === "GestaoAvistaAtivos",
          route: "/gestao-a-vista-ativos",
        },
        {
          id: "Gestão a Vista",
          label: "Gestão a Vista - (Lorawan | I-alert)",
          icon: <SpeedIcon />,
          active: actual_page === "GestaoAVista",
          route: "/gestao-a-vista",
        },
        {
          id: "Saúde da Planta",
          label: "Saúde da Planta",
          icon: <SpeedIcon />,
          active: actual_page == "SaudePlanta",
          route: "/saude-planta"
        },
        {
          id: "Ativos",
          label: "Analise Ativos - App",
          icon: <SpeedIcon />,
          active: actual_page == "Ativos",
          route: "/analise-ativos"
        },
        {
          id: "Analise",
          label: "Analise (Lorawan | I-alert)",
          icon: <SpeedIcon />,
          active: actual_page == "Analise",
          route: "/analise",
        },
        {
          id: "Cascata",
          label: "Cascata",
          icon: <SpeedIcon />,
          active: actual_page == "Cascata",
          route: "/cascata",
          role: ["sup_admin"]
        }
      ]
    },
    {
      id: "Configurações",
      children: [
        {
          id: "ProvibAtivos",
          label: "Ativos Provib - App",
          icon: <PrecisionManufacturingIcon />,
          active: actual_page == "ProvibAtivos",
          route: "/ativos_provib",
          required_permission: "provibAtivos_listar"
        },
        {
          id: "Dispositivos",
          label: "Dispositivos (Lorawan | I-alert)",
          icon: <DnsRoundedIcon />,
          active: actual_page == "Dispositivos",
          route: "/dispositivos",
          required_permission: "dispositivo_listar"
        },
        {
          id: "GruposDispositivos",
          label: "Grupo Dispositivos (Lorawan | Ialert)",
          icon: <GroupWorkIcon />,
          active: actual_page == "GruposDispositivos",
          route: "/grupo-dispositivos",
          required_permission: "grupoDispositivo_listar"
        },
        {
          id: "Alarmes",
          label: "Alarmes",
          icon: <NotificationImportantIcon />,
          active: actual_page == "Alarmes",
          route: "/alarmes",
          required_permission: "alarmes_listar"
        },
        {
          id: "Usuarios",
          label: "Usuarios",
          icon: <PersonIcon />,
          active: actual_page == "Usuarios",
          route: "/usuarios",
          required_permission: "usuarios_listar"
        },
        {
          id: "Clientes",
          label: "Clientes",
          icon: <PersonIcon />,
          active: actual_page == "Clientes",
          route: "/clientes",
          required_permission: "clientes_listar"
        },
      ],
    },
  ];
  
  let filteredCategories: { id: string; children: ({ id: string; label: string; icon: JSX.Element; active: boolean; route: string; role?: undefined, required_permission?: undefined; } | { id: string; label: string; icon: JSX.Element; active: boolean; route: string; role: string[]; required_permission: string })[]; }[] = [];

  if (userRole == "sup_admin" || user?.permissions === "*") {
    filteredCategories = categories;
  } else {  
    categories.forEach((category: any) => {
      let children = category.children.filter((child: any) => {


        if (child.role) {
          return child.role.includes(userRole);
        }
        if (child.required_permission) {
          if (user?.permissions) {
            return user.permissions.includes(child.required_permission);
          }
          return false;
        }
        return true;
      });
      if (children.length > 0) {
        filteredCategories.push({ id: category.id, children: children });
      }
    });
  }
  return (
    <>
      <AppBar position="static" color="default" sx={{ backgroundColor: '#081627' }}>
        <Toolbar>
          <IconButton
            color="default"
            aria-label="open drawer"
            edge="start"
            onClick={() => setOpen(!open)}
            sx={{ mr: 2 }}
          >
            <MenuIcon sx={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" color={"white"} noWrap component="div" sx={{ flexGrow: 1 }}>
            Provib Telemetria
          </Typography>

          <IconButton color="inherit" onClick={() => { window.sessionStorage.removeItem("provib_telemetria"); window.location.pathname = '/login'; }}>
            <LogoutIcon sx={{ color: 'white' }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
      >
        <List>
          <ListItem
            sx={{ ...item, ...itemCategory, fontSize: 22, color: "#fff" }}
          >
            <ListItemText primary="Provib Telemetria" />

          </ListItem>
          {actual_page !== "Home" &&
            filteredCategories.map(({ id, children }) => (
              <Box key={id}>
                <ListItem sx={item}>
                  <ListItemText primary={id} />
                </ListItem>
                {children.map(({ id: childId, label, icon, active, route, role }) => (
                  <ListItem disablePadding key={childId}>
                    <ListItemButton
                      selected={active}
                      sx={item}
                      onClick={() => { navigate(route); setOpen(false); }}
                    >
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText primary={label ? label : childId} />
                    </ListItemButton>
                  </ListItem>
                ))}
                <Divider />
              </Box>
            ))}

        </List>
        <Box display="flex" flexDirection="column" height="100%">
          <Box flexGrow={1} />
          <SelectSistemas sistemaSelecionado={sistemaSelecionado} setSistemaSelecionado={setSistemaSelecionado} />
        </Box>
      </Drawer>
    </>
  );
}

function SelectSistemas(params: { sistemaSelecionado: string | null, setSistemaSelecionado: Function }) {
  let defaultSistema = window.localStorage.getItem('last_sistema_id');
  
  const { isPending, error, data, isFetching } = useQuery({
    queryKey: ['SistemasList'],
    queryFn: () => ClienteRepo.getAll(),
    staleTime: Infinity,
    placeholderData: [],
  })

  if (isPending || isFetching) return <p style={{ color: 'white', margin: 'auto', marginTop: '10px' }}>Loading...</p>
  if (error) return <p>Error: {error.message}</p>

  if (!data || data.length <= 0) return <></>

  if (data.length === 1) {
    params.setSistemaSelecionado(data[0].id);
    return <></>
  }
  return <Select
    sx={{ margin: 'dense', backgroundColor: 'white' }}
    defaultValue={defaultSistema}
    value={params.sistemaSelecionado ?? ""}
    onChange={(e) => {
      params.setSistemaSelecionado(e.target.value);
      window.localStorage.setItem('last_sistema_id', e.target.value ?? "");
    }}
    fullWidth
  >
    {data.map((sistema: Cliente) => (
      <MenuItem key={sistema.id} value={sistema.id}>{sistema.nome}</MenuItem>)
    )}
  </Select>
}